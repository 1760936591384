function useFetchPost(url, data) {
    async function fetchUrl() {
        const response = fetch(url, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, cors, *same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "omit", // include, *same-origin, omit
            headers: {
                "Content-Type": "application/json"
            },
            redirect: "follow", // manual, *folslow, error
            referrer: "client", // no-referrer, *client
            body: JSON.stringify(data) // body data type must match "Content-Type" header
        });
        const json = response;
        return json;
    }
    return fetchUrl();
}

export default useFetchPost;