import React from "react";
import Slider from "react-slick";

const TeamSeven = ({ data, title, subtitle, items }) => {
  const settings = {
    className: "team-slider",
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
    dots: true,
    prevArrow: false,
    nextArrow: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  console.log('ITEMS', items);

  return (
    <section className="dark-block" id="gallery">
      <div className="container">
        <div className="row">
          <div className="col-sm-8 section-heading">
            <div className="title-section text-center">
              <h2 data-backletter={subtitle}>{title}</h2>
            </div>
          </div>
        </div>

        <div className="row mt-50">
          <div className="col-md-12 remove-padding">
            <Slider {...settings}>
              {items.map((item, index) => (
                <div className="member" key={`slider-${index}`}>
                  <div className="team-member-container gallery-image-hover">
                    <img
                      src={item.image}
                      className="img-responsive"
                      alt={item.text}
                    />
                    <div className="member-caption">
                      <div className="member-description">
                        <div className="member-description-wrap">
                          <h4 className="member-title">
                            <a href={process.env.PUBLIC_URL}>{item.text}</a>
                          </h4>
                          <p className="member-subtitle">{item.subtext}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TeamSeven;
