import React from "react";
import img from "../../assets/img/logo-branco.png";

const FooterTwo = ({address, phone, email, facebook, instagram}) => (
  <>
    <footer className="footer dark-block" id="contact">
      <div className="footer-main">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-md-4">
              <div className="widget widget-text">
                <div className="logo logo-footer">
                  <a href="index.html">
                    <img className="logo logo-display" height="200" src={img} alt="" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-sm-6 text-end m-auto">
              <div className="widget-links">
                <ul>
                  <li>{address}</li>
                  <li>{phone}</li>
                  <li>{email}</li>
                  {/*  <li>www.grandy.com</li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-xs-12">
              <ul className="social-media">
                <li>
                  <a
                    href={facebook}
                    className="ion-social-facebook"
                  >
                    <span></span>
                  </a>
                </li>
                <li>
                  <a
                    href={instagram}
                    className="ion-social-instagram"
                  >
                    <span></span>
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-6 col-xs-12">
              <div className="copy-right text-end">
                © {new Date().getFullYear()} CloserDev. All rights reserved
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
    {/* <div className="footer-height"></div> */}
  </>
);

export default FooterTwo;
