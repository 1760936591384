import React from "react";
import { graphql, useStaticQuery } from 'gatsby';
// DATA Files
import dataNavbar from "../data/Navbar/nav-restaurant-data.json";
import dataMenu from "../data/Menu/menu-data.json";
import dataServices from "../data/Services/services-seven-data.json";
import dataTeam from "../data/Team/team-one-data.json";
// Images
import imgWelcome from "../assets/img/story-bg.png";
// Components
import NavbarSimpleSocial from "../components/Navbar/NavbarSimpleSocial";
import NavbarSimple from "../components/Navbar/NavbarSimple";
import RestaurantSlider from "../components/Slider/RestaurantSlider";
import FooterTwo from "../components/Footer/FooterTwo";
import WelcomeTwo from "../components/Welcome/WelcomeTwo";
import MenuOne from "../components/Menu/MenuOne";
import ServiceThree from "../components/Service/ServiceThree";
import MapSection from "../components/Map/MapSection";
import ContactSix from "../components/Contact/ContactSix";
import Loader from "../components/Loader/Loader";
import TeamSeven from "../components/Team/TeamSeven";

const HomeRestaurant = () => {
  const { content } = useStaticQuery(pageQuery).mongodbCloserDevCmsPages;
  const { 
    title,
    buttonText,
    story,
    gallery,
    menu,
    contact,
    footer
  } = content;
  return (
    <Loader>
      <NavbarSimple data={dataNavbar}></NavbarSimple>
      <RestaurantSlider title={title} buttonText={buttonText} />
      <WelcomeTwo image={imgWelcome} {...story} />
      <TeamSeven data={dataTeam} {...gallery} />
      <MenuOne data={dataMenu} {...menu} />
      {/* <ServiceThree
        data={dataServices}
        title="The Food Lovers"
        tagline="Our Services"
      /> */}
      {/* <MapSection /> */}
      <ContactSix {...contact} />
      <FooterTwo {...footer} />
    </Loader>
  )
};

export default HomeRestaurant;
export const pageQuery = graphql`
  query {
    mongodbCloserDevCmsPages(mongodb_id: { eq: "60d8a7b245e074d222d8b0db" }) {
      content {
        title
        buttonText
        story {
          title
          header
          text
        }
        gallery {
          subtitle
          title
          items {
            image
            text
            subtext
          }
        }
        menu {
          title
          subtitle
          sections {
            title
            items {
              image
              title
              price
              text
              subtext
            }
          }
        }
        contact {
          title
          subtitle
          buttonText
        },
        footer {
          address
          phone
          email
          facebook
          instagram        
        }
      }
    }
  }
`;