import React from "react";
import Slider from "react-slick";

const MenuOne = ({ title, subtitle, sections }) => {
  const settings = {
    className: "menu-slider",
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
    dots: true,
    prevArrow: false,
    nextArrow: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section id="menu" className="dark-bg">
    <div className="container">
      <div className="row">
        <div className="col-md-12 text-center col-md-offset-2">
          <h1 className="mb-0 kaushan-font orange-color font-70px">
            {title}
          </h1>
          <h2 className="mt-10 text-uppercase font-700 letter-spacing-10 white-color">
            {subtitle}
          </h2>
        </div>
      </div>

      <div className="row mt-50">
        <div className="col-md-12 remove-padding">
          <Slider {...settings}>
            {sections.map((dataItem, index) => (
              <div className="member" key={`menu-${dataItem.title}-${index}`}>
                <div className="team-member-container gallery-image-hover">
                  <div className="row">
                    <h3 className="mt-10 ml-15 text-uppercase font-700 letter-spacing-10 white-color">
                      {dataItem.title}
                    </h3>
                  </div>
                  <div className="row">
                  {dataItem.items.map((item, indexItem) => (
                    <div className="col-md-6" key={`item-${dataItem.title}-${indexItem}`}> 
                      <div
                        className="circle-content-box pt-50 pr-50 pb-50 pl-50 mt-80"
                        key={`item-${indexItem}`}
                      >
                        <div className="menu-text-content">
                          <div className="row">
                            <div className="col-md-8">
                              <h3>{item.text}</h3>
                              <h6>{item.subtext}</h6>
                            </div>
                            <div className="col-md-4">
                              <h3 className="text-end">{item.price}</h3>
                              {/*  <div className="mt-20">
                                <a href={process.env.PUBLIC_URL}>
                                  <span className="label label-default">Order</span>
                                </a>
                              </div> */}
                            </div>
                          </div>
                        </div>
                        <p>{item.text}</p>
                      </div>
                    </div>
                  ))}
                  {/* <div className="col-md-6">
                    <img src={img1} alt="gallery" />
                  </div>
                  <div className="col-md-6"> 
                    <div
                      className="circle-content-box pt-50 pr-50 pb-50 pl-50 mt-80"
                      key={item.id}
                    >
                      <div className="menu-text-content">
                        <div className="row">
                          <div className="col-md-8">
                            <h3>{item.title}</h3>
                            <h6>{item.tags}</h6>
                          </div>
                          <div className="col-md-4">
                            <h3>{item.currency + item.price}</h3>
                            <div className="mt-20">
                              <a href={process.env.PUBLIC_URL}>
                                <span className="label label-default">Order</span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p>{item.text}</p>
                    </div>
                  </div> */}
                </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>

      {/* <div className="row mt-50">
        <div className="col-md-6">
          {data.slice(2, 4).map((item) => (
            <div
              className="circle-content-box pt-50 pr-50 pb-50 pl-50 mt-80"
              key={item.id}
            >
              <div className="menu-text-content">
                <div className="row">
                  <div className="col-md-8">
                    <h3>{item.title}</h3>
                    <h6>{item.tags}</h6>
                  </div>
                  <div className="col-md-4">
                    <h3>{item.currency + item.price}</h3>
                    <div className="mt-20">
                      <a href={process.env.PUBLIC_URL}>
                        <span className="label label-default">Order</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <p>{item.text}</p>
            </div>
          ))}
        </div>

        <div className="col-md-6">
          <img src={img2} alt="gallery" />
        </div>
      </div>

      <div className="row mt-50">
        <div className="col-md-6">
          <img src={img3} alt="gallery" />
        </div>
        <div className="col-md-6">
          {data.slice(4, 6).map((item) => (
            <div
              className="circle-content-box pt-50 pr-50 pb-50 pl-50 mt-80"
              key={item.id}
            >
              <div className="menu-text-content">
                <div className="row">
                  <div className="col-md-8">
                    <h3>{item.title}</h3>
                    <h6>{item.tags}</h6>
                  </div>
                  <div className="col-md-4">
                    <h3>{item.currency + item.price}</h3>
                    <div className="mt-20">
                      <a href={process.env.PUBLIC_URL}>
                        <span className="label label-default">Order</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <p>{item.text}</p>
            </div>
          ))}
        </div>
      </div> */}
    </div>
  </section>
  )
};

export default MenuOne;
