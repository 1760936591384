import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";
import useWindowSize from "../../helpers/useWindowSize";
import img from "../../assets/img/logo-preto.png";


const NavbarSimple = ({ data }) => {
    const [collapse, setCollapse] = useState(true);
    const width = useWindowSize().width;
    const handleCollapse = (e) => {
        e.preventDefault();
        setCollapse(!collapse);
    };
  return (
    <div className="container">
      <nav className="navbar fixed-top navbar-expand-lg navbar-light bg-light white bootsnav on no-full">
        <div className="container-fluid">
          <a className="navbar-brand p-0 h-100" href="#">
            <img src={img} alt="" height="60" className="d-inline-block align-text-top"/>
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbar-menu"
            aria-controls="navbar-menu"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={handleCollapse}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <CSSTransition in={collapse} timeout={5000} classNames="collapse-navbar">
          <div className="collapse navbar-collapse" id="navbar-menu">
            <ul className="nav navbar-nav navbar-right">
                {data.map((item) => (
                    <li key={item.id}>
                        <a className="page-scroll" href={`#${item.link}`}>
                            {item.title}
                        </a>
                    </li>
              ))}
            </ul>
          </div>
          </CSSTransition>
        </div>
      </nav>
    </div>
  );
};

export default NavbarSimple;
