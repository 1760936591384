import React, { useState } from "react";
import useFetchPost from '../../helpers/http';


const ContactSix = ({ title, subtitle, buttonText }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState('');
  const [formMessage, setformMessage] = useState('');

  const sendemail = () => {
    setLoading(true);
    useFetchPost
      .call(this, url, {
        mailTo: 'info@nemourslanderon.ch',
        subject: 'Client reservation',
        fromName: name,
        message,
        phone,
        email,
        nome: name,
      })
      .then((res) => {
        setLoading(false);
        setName('');
        setEmail('');
        setPhone('');
        setMessage('');
        setformMessage('Mesagem enviada!');
      })
      .catch((err) => {
        setLoading(false);
        setformMessage('Aconteceu algo de errado...');
        console.log(err);
      });
  };
  const isLoading = () => {
    return loading;
  };
  const getMessage = () => {
    return formMessage;
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    sendemail.call(this);
  };
  // setLoading(false)
  const url = 'https://emailservice.closerdev.com/api/sendemail';
  return (
    <section id="bookAtable" className="white-bg form-style-one">
    <div className="container">
      <div className="row">
        <div className="col-md-12 text-center col-md-offset-2">
          <h1 className="mb-0 kaushan-font orange-color font-70px">{title}</h1>
          <h2 className="mt-10 text-uppercase font-700 letter-spacing-10">
            {subtitle}
          </h2>
        </div>
      </div>
      <div className="mt-5 row">
        <div className="col-md-8 offset-md-2">
          <table class="opening-hours-table mx-auto">
                <tr id="Monday" itemprop="openingHours" title="Open Monday at 9am to 6pm">
                    <td>Lundi, Mercredi</td>
                    <td class="opens pl-10">Fermé</td>
                </tr>
                <tr id="Tuesday" itemprop="openingHours" title="Open Tuesday at 9am to 6pm">
                    <td>Mardi, Jeudi</td>
                    <td class="opens pl-10">09:00-23:00</td>
                </tr>
                <tr id="Wednesday" itemprop="openingHours" title="Open Wednesday at 9am to 6pm">
                    <td>Vendredi, Samedi</td>
                    <td class="opens pl-10">09:00-23:00</td>
                </tr>
                <tr id="Thursday" itemprop="openingHours" title="Open Thursday at 9am to 8pm">
                    <td>Dimanche</td>
                    <td class="opens pl-10">10:00-22:00</td>
                </tr>
            </table>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-md-8 offset-md-2">
          <form
            name="contact-form"
            id="contact-form"
            onSubmit={handleSubmit}
          >
            <div className="messages"></div>
            <div className="row">
              <div className="col-md-6 col-sm-6">
                <div className="form-group">
                  <label className="sr-only" htmlFor="name">
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    id="name"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    required
                    placeholder="Your Name"
                    data-error="Your Name is Required"
                  />
                  <div className="help-block with-errors mt-20"></div>
                </div>
              </div>
              <div className="col-md-6 col-sm-6">
                <div className="form-group">
                  <label className="sr-only" htmlFor="email">
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="form-control"
                    id="email"
                    placeholder="Your Email"
                    required
                    data-error="Please Enter Valid Email"
                  />
                  <div className="help-block with-errors mt-20"></div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div className="form-group">
                  <label className="sr-only" htmlFor="phone">
                    Phone number
                  </label>
                  <input
                    type="text"
                    name="phone"
                    onChange={(e) => setPhone(e.target.value)}
                    value={phone}
                    className="form-control"
                    id="subject-2"
                    placeholder="Your phone number"
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="sr-only" htmlFor="message">
                Message
              </label>
              <textarea
                name="message"
                className="form-control"
                id="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                rows="7"
                placeholder="Your Message"
                required
                data-error="Please, Leave us a message"
              ></textarea>
              <div className="help-block with-errors mt-20"></div>
            </div>
            <div className="text-left">
              <button
                type="submit"
                name="submit"
                className="btn btn-outline btn-md btn-circle btn-animate remove-margin"
              >
                <span>
                  {buttonText} <i className="ion-android-arrow-forward"></i>
                </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  );
}
export default ContactSix;
